<template>
  <div>
    <div class="container">
      <div class="">

        <!--<h1 class="my-4">Music</h1>-->

        <div v-for="(song, index) in songs" class="card p-5 mb-4">

          <!--<hr v-if="index > 0">-->

          <router-link :to="{ name: 'music-detail', params: { filename: song.filename } }">
            <h3>
                {{ song.name }}
            </h3>

            <MusicPlayer
              :filename="song.filename"
              extension="mp3"
              :name="song.name"
              :bg-index="index"
              preview
            ></MusicPlayer>
          </router-link>

        </div>

      </div>

    </div>
  </div>
</template>

<script>

import MusicPlayer from './music-player'

import store from '../store'

export default {
  components: { MusicPlayer },
  data() {
    return {
    }
  },
  computed: {
    songs() {
      return store.getters['songs']
    }
  },
  created() {
  }
}

</script>
